import React from 'react'
import { RichText } from '../Shared/RichText/RichText'
import * as styles from './contactUsCards.module.scss'
import {Link} from 'gatsby'
import {useLocalPath} from '../hooks/useLocalPath'
import { GatsbyImage } from 'gatsby-plugin-image'

const ContactUsCards = ({gridItems, alignment, title}) => {

  return (
    <div className={`container container-lg `}>
        <div className={alignment === 'row' ? styles.wrapperRow :styles.wrapperColumn}>
            {gridItems?.length>=1 && <h2 className={styles.heading}>{title}</h2>}
            {gridItems?.map((item:any, id:number) =>{
                const{title, description, cta, image} = item
                return(
                    <div key = {id} className = {` ${ gridItems.length > 1 ? styles.cardWrapper : styles.singleCardWrapper} `}>
                        <div className={styles.card}>
                            <GatsbyImage
                                image = {image.gatsbyImageData}
                                objectFit= 'contain'
                                className={styles.cardImage}
                                alt = {`${image.title} image`} />
                            <h3 className={styles.title}>{title}</h3>
                            <div className={styles.desc}><RichText content={description} /></div>
                            <div className={` ${cta.slug ? styles.activeCta :styles.cta }`}> <Link to = {useLocalPath(cta.slug)}> {cta.title} </Link> </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default ContactUsCards